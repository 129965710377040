import SeccionesBar from "../../components/SeccionesBar";
import { AppBar, Button, ButtonGroup, IconButton, Paper, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import APIManager from '../../lib/apiManager';
import Swal from 'sweetalert2';

const Reserva = ({ reservaSelected, setReservaSelected, enSeccion, setEnSeccion, ...props }) => {

  const [reserva, setReserva] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [seccionBack, setSeccionBack] = useState()
  const [seccionForward, setSeccionForward] = useState()
  const [seccionForwardText, setSeccionForwardText] = useState('SIGUIENTE')
  const [seccionBackText, setSeccionBackText] = useState('ANTERIOR')

  const handleStepper = (seccion) => {
    setSeccionBack(parseInt(seccion) <= 1 ? 1 : parseInt(seccion) - 1)
    setSeccionForward(parseInt(seccion) >= 4 ? 4 : parseInt(seccion) + 1)

    seccion == 4 ? setSeccionForwardText('CONFIRMAR') : setSeccionForwardText('SIGUIENTE')
    seccion == 1 ? setSeccionBackText('CANCELAR') : setSeccionBackText('ANTERIOR')
  }

  const handleChange = (event) => {

    setEnSeccion(parseInt(event.target.value))
    handleStepper(parseInt(event.target.value))


    if ((event.target.value == 4) && (enSeccion == 4) && (seccionForward == 4)) {
      handleConfirmar()
    } else
      if ((event.target.value == 1) && (enSeccion == 1) && (seccionBack == 1)) {
        navigate('/search');
    } else {
        navigate('/reserva/seccion' + event.target.value);
    }

  }

  useEffect(() => {
    handleStepper(enSeccion);
  }, [enSeccion]);

  useEffect(() => {
    const path = location.pathname.split('/', 3).pop();
    if (path.includes('seccion')) {
      setEnSeccion(parseInt(path[7]));
    } else {
      navigate('/reserva/seccion1');
    }
  }, [location]);

  const handleConfirmar = () => {
    Swal.fire({
      title: 'Confirmacion de reserva',
      html: `Desea confirmar la reserva ? <br/> <br/> ${reserva.codigoReserva} <br/> <br/> ${reserva.institucion} `,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#08996B',
      cancelButtonColor: '#7A0700',
      confirmButtonText: 'ACEPTAR',
      cancelButtonText: 'CANCELAR',
      // reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {

        APIManager.sendRequest('post', 'reserva', 'confirmarReserva', { reserva: reserva }, (response) => {
          if (response.success) {

            const result = response.data.codigo;
            const usuario = response.data?.usuario;

            if (result == 'confirmada') {
              Swal.fire({
                title: 'Reserva confirmada',
                html: `La reserva ha sido confirmada con éxito. <br/> <br/> Ya pueden proceder a ingresar al parque.`,
                icon: 'success',
                confirmButtonColor: '#08996B',
                confirmButtonText: 'ACEPTAR',
              }).then(() => {
                navigate('/search');
              })
            }

            if (result == 'confirmadaConDiferencias') {
              Swal.fire({
                title: 'Reserva confirmada',
                html: `La reserva ha sido confirmada con éxito. <br/> <br/> Ya pueden proceder a <b>pagar la diferencia</b> en boleteria.`,
                icon: 'warning',
                confirmButtonColor: '#08996B',
                confirmButtonText: 'ACEPTAR',
              }).then(() => {
                navigate('/search');
              })
            }

            if (result == 'noConfirmada') {
              Swal.fire({
                title: 'Reserva pendiente de supervisor del CAV',
                html: `La reserva supera los $10.000, enviar al CAV para definir método de pago.`,
                icon: 'error',
                confirmButtonColor: '#08996B',
                confirmButtonText: 'ACEPTAR',
              }).then(() => {
                navigate('/search');
              })
            }

            if (result == 'noConfirmadaPreviamenteConfirmada') {
              Swal.fire({
                title: 'Reserva no confirmada',
                html: 'La reserva ha sido previamente confirmada'+(usuario ? (' por '+usuario) : '') +'</b>.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ACEPTAR',
              })
            }

          } else {
            Swal.fire({
              title: 'Error al confirmar la reserva',
                  html: response?.errors?.length > 0 ? response.errors[0].message : response,
              icon: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ACEPTAR',
            })
          }
        });


      }
    })
  }

  const closeReserva = () => {
    localStorage.setItem('codigoReserva', null);
    navigate('/search');
  }

  useEffect(() => {
    if (!reserva) {
      if (!!reservaSelected) {
        setReserva(reservaSelected);
      } else {
        const codigoReserva = localStorage.getItem('codigoReserva');
        APIManager.sendRequest('post', 'reserva', 'getReserva', { codigoReserva: codigoReserva }, (responseData) => {
          if (responseData.success) {
            setReserva(responseData.data);
            setReservaSelected(responseData.data);
          } else {
            navigate('/search');
          }
        });
      }
    }

    // Aprovecho el Effect y recargo los valores del Stepper ':)'
    handleStepper(parseInt(enSeccion))

  }, []);

  return (
    <Stack className='main-content reserva-page' spacing={2} >
      <AppBar position="static" sx={{ backgroundColor: 'lightyellow', color: 'darkslategrey'}}>
        {!!reserva &&
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div" className='status-bar__title' >
              {reserva.codigoReserva}
            </Typography>
            <Typography variant="h6" color="inherit" component="div" className='status-bar__title' >
              {reserva.institucion}
            </Typography>
            <IconButton color='inherit' className='status-bar__exit' onClick={closeReserva}><Close /></IconButton>
          </Toolbar>
        }
      </AppBar>
      <Stack className="reserva-content" direction="row">
        <SeccionesBar setEnSeccion={setEnSeccion} enSeccion={enSeccion} />
        <Stack className="seccion-content" direction={"column"}>
          <Paper sx={{overflowY: 'scroll', flexGrow: 1}}>
            <Outlet />
          </Paper>
          <Box flexGrow={0} padding={3} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* <ButtonGroup variant="contained" sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}> */}
              <Button variant="contained" onClick={handleChange} value={seccionBack}
                sx={{
                  backgroundColor: "#7A0700",
                }}
              >{seccionBackText}</Button>
              <Button variant="contained" onClick={handleChange} value={seccionForward}>{seccionForwardText}</Button>
            {/* </ButtonGroup> */}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Reserva;