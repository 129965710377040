import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { useState } from 'react';

const Seccion4 = ({...props}) => {

  const {isPortrait, reserveSelected, handleCantidades} = props

  return (
    <>
      {isPortrait ?
        <>Mobile</>
      :
        <>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
{/*
            <Grid item xs={6} order={{ md: 1, lg: 1 }}>
              // {Seccion 1}
              <TableContainer component={Paper} sx={{ width: 500, margin: 5 }}>
                <Table sx={{ width: 500}} aria-label="simple table">
                  <TableBody>
                    {reserveSelected?.detalleReserva.map((item, index) => {
                      return(
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row">
                            {item.label}
                          </TableCell>
                          <TableCell>{item.value}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
 */}

            <Grid item xs={6} order={{ md: 2, lg: 3 }}>
              {/* {Seccion 2} */}
              <TableContainer component={Paper} sx={{ width: 500, margin: 5 }}>
                <Table sx={{ width: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 320 }}>Entrada</TableCell>
                      <TableCell align="center">Cantidad</TableCell>
                      <TableCell align="center">Liberados</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reserveSelected?.entradas.map((item, index) => {
                      const displayCounter = 0;
                      return(
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row">
                            {item.descripcion}
                          </TableCell>
                          <TableCell align="center">
                            {item.cantidad}
                          </TableCell>
                          <TableCell align="center">
                            {item.cantidadLiberados}
                          </TableCell>
                          <TableCell align="center">
                            {parseInt(item.cantidadLiberados) + parseInt(item.cantidad)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} sx={{ width: 500, margin: 5 }}>
                <Table sx={{ width: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                    <TableCell sx={{ width: 320 }}>Entrada</TableCell>
                      <TableCell align="center">Cantidad</TableCell>
                      <TableCell align="center">Liberados</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reserveSelected?.estacionamiento.map((item, index) => {
                      return(
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell component="th" scope="row">
                            {item.descripcion}
                          </TableCell>
                          <TableCell align="center">
                            {item.cantidad}
                          </TableCell>
                          <TableCell align="center">
                            {item.cantidadLiberados}
                          </TableCell>
                          <TableCell align="center">
                            {parseInt(item.cantidadLiberados) + parseInt(item.cantidad)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>

            <Grid item xs={6} order={{ md: 3, lg: 2 }}>
              {/* {Seccion 3} */}
              {reserveSelected?.gastronomia.map((element, index) => {
                return(
                  <>
                    <TableContainer component={Paper} sx={{ width: 500, margin: 5 }}>
                      <Table sx={{ width: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={4}>{element.actividad} ({element.horario})</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ width: 320 }}>Producto</TableCell>
                            <TableCell align="center">Cantidad</TableCell>
                            <TableCell align="center">Liberados</TableCell>
                            <TableCell align="center">Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {element.combosGastronomicos.map((item, index) => {
                            const displayCounter = 0;
                            return(
                              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="th" scope="row">
                                  {item.descripcion}
                                </TableCell>
                                <TableCell align="center">
                                  {item.cantidad}
                                </TableCell>
                                <TableCell align="center">
                                  {item.cantidadLiberados}
                                </TableCell>
                                <TableCell align="center">
                                  {parseInt(item.cantidadLiberados) + parseInt(item.cantidad)}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )
              })}

            </Grid>

          </Grid>


        </>
      }
    </>
  )
}

export default Seccion4;