import { useEffect, useState } from "react";
import { Tab } from '@mui/material';
import { Tabs } from '@mui/material';
import { BottomNavigationAction } from '@mui/material';
import { BottomNavigation } from '@mui/material';
import { EventNote as EventNoteIcon, People as PeopleIcon, Restaurant as RestaurantIcon, PlaylistAddCheck as PlaylistAddCheckIcon } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const SeccionesBar = ({enSeccion, setEnSeccion, ...props}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(1);

  const path = location.pathname.split('/', 3);

  const handleChange = (event, newValue) => {
    setEnSeccion(parseInt(newValue))
    navigate('/reserva/seccion' + newValue);
    setValue(newValue);
  }

  useEffect(() => {
    console.log(enSeccion);
    setValue(enSeccion);
  }, [enSeccion]);

  return (
    <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example" orientation="vertical" variant="fullWidth"
      sx={{width: '200px'}}>
      <Tab value={1} icon={<EventNoteIcon />} label="INFORMACIÓN" />
      <Tab value={2} icon={<PeopleIcon />} label="ENTRADAS / ESTACIONAMIENTOS" />
      <Tab value={3} icon={<RestaurantIcon />} label="GASTRONOMÍA" />
      <Tab value={4} icon={<PlaylistAddCheckIcon />} label="Confirmación" />
    </Tabs>
  );
}

export default SeccionesBar;