import { useEffect, useState } from 'react';
import ReservaCard from '../../components/ReservaCard';
import { Paper, Stack } from '@mui/material';
import { ListItem } from '@mui/material';
import { List } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { TextField } from '@mui/material';
import { Search as SearchIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import APIManager from '../../lib/apiManager';
import { useNavigate } from 'react-router-dom';

const Search = ({ isPortrait, setReservaSelected, ...props }) => {
  const [codigoDeReserva, setCodigoDeReserva] = useState('');

  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  const actualizarListado = () => {
    APIManager.sendRequest('post', 'reserva', 'getReservas', {codigo: codigoDeReserva}, (responseData) => {
      if (responseData.success) {
        setItems(responseData.data);
      }
    })
  }

  const cargarReserva = (item) => {
    APIManager.sendRequest('post', 'reserva', 'getReserva', { codigoReserva: item.codigoReserva }, (responseData) => {
      if (responseData.success) {
        setReservaSelected(responseData.data);
        localStorage.setItem('codigoReserva', item.codigoReserva);
        navigate('/reserva');
      }
    });

  }

  useEffect(() => {
    if (items.length == 0) {
      actualizarListado();
    }
  }, []);

  return (
    <Stack className='main-content search-page' spacing={2} padding={3}>
      <Stack direction="row" className='search-inputs'>
        <Stack direction="row" className='search-inputs__codigo'>
          <TextField className='codigo-input' id="filled-search" label="Reserva" variant="filled" value={codigoDeReserva} onChange={(event) => setCodigoDeReserva(event.target.value)} />
          {/* <Button className='search-button' variant="contained" color="primary" onClick={actualizarListado}>
            <SearchIcon />
          </Button> */}
        </Stack>
        <Button variant="contained" color="primary" className='refresh-button' endIcon={<RefreshIcon />} onClick={actualizarListado}>
          Actualizar reservas del día
        </Button>
      </Stack>
      <Paper className='reservas-list' elevation={3}>
        <List>
          {items.filter(item => (!codigoDeReserva || (item.codigoReserva.includes(codigoDeReserva)))
           ).map((item, key) => <ListItem key={key}><ReservaCard reserva={item} onSelected={() => cargarReserva(item)} /></ListItem>)}
        </List>
      </Paper>
      {/* <ol className='reservas-list'>
        {items.map((item, key) => <li key={key} className='reservas-list__item'><ReservaCard reserva={item} /></li>)}
      </ol> */}
    </Stack>
  );
}

export default Search;