import axios from "axios";
import {
  v4 as uuidv4,
  parse as uuidParse,
  stringify as uuidStringify,
} from "uuid";

export default class APIManager {
  static current = null;
  static API_PATH = 'https://api.pedi2.softguild.com.ar';

  constructor(dispatch, history) {
    this.myUUID = null;
    this.dispatch = dispatch;
    this.history = history;
    APIManager.current = this;
  }

  sendRequest = (endpoint, accion, data, callback) => {
    APIManager.sendRequest('post', endpoint, accion, data, (response) => {
      if (response.sistema) {
        if (response.sistema.usuario) {
          this.dispatch({'type': 'sistema/setUsuario', payload: response.sistema.usuario});
        }
        // if (typeof response.sistema.miembro !== 'undefined') {
        //   this.dispatch({'type': 'sistema/setMiembro', payload: response.sistema.miembro});
        // }
        // if (response.sistema.adminMenu) {
        //   this.dispatch({ 'type': 'admin/setMenu', payload: response.sistema.adminMenu });
        // }
        if (response.sistema.staff) {
          this.dispatch({'type': 'sistema/setStaff', payload: response.sistema.staff});
        }
        if (response.sistema.noImageURL) {
          this.dispatch({'type': 'sistema/setNoImageURL', payload: response.sistema.noImageURL});
        }
      }
      if (callback) {
        callback(response);
      }
    });
  }

  getUIData = (accion, postData = {}, callback, errorCallback) => {
    this.sendRequest("ui", accion, postData, (response) => {
      if (response.success) {
        if (callback) {
          callback(response.data)
        };
      } else {
        if (errorCallback) {
          errorCallback(response);
        }
      }
    });
  };

  getList(lista, data = {}, successCallback, errorCallback) {
    data.lista = lista;
    this.sendRequest('query','getList', data, (response) => {
      if (response.success && successCallback) {
        return successCallback(response);
      }
      if (!response.success && errorCallback) {
        return errorCallback(response);
      }
    })
  }

  navigate(pagina, toggleNavMenu = false) {
    this.dispatch({ type: 'sistema/setPaginaActual', payload: pagina });
    if (toggleNavMenu) {
      toggleNavMenu();
    }
  }

  uploadImages = (images, callback) => {
    if (!images || (images.length === 0)) {
      return false;
    }

    const accion = 'uploadImages';
    const data = new FormData();

    const postData = {};
    postData.uuid = APIManager.getUUID();
    postData.authToken = APIManager.getAuthToken();
    postData.app = "Pedi2-client";
    postData.accion = accion;

/*
    data.append('uuid', APIManager.getUUID());
    data.append('authToken', APIManager.getAuthToken());
    data.append('app', "Pedi2-client");
    data.append('accion', accion);
*/
    data.append('data', JSON.stringify(postData));
    data.append('image', images[0].file, images[0].imagen);

    this.sendRequest('ui', accion, data, callback);
  };

  static getUUID = () => {
    let myUUID = JSON.parse(localStorage.getItem("uuid"));
    if (!myUUID) {
      myUUID = uuidv4();
      myUUID = uuidParse(myUUID);
      localStorage.setItem("uuid", JSON.stringify(myUUID));
    }
    this.current.myUUID = myUUID;
    return uuidStringify(myUUID);
  };

  static setAuthToken = (newToken) => {
    localStorage.setItem("authToken", newToken);
    document.cookie = "PHPSESSID=" + newToken;
  };

  static getAuthToken = (newToken) => {
    if (newToken) {
      this.setAuthToken(newToken);
    }
    const authToken = localStorage.getItem("authToken");
    document.cookie = "authToken=" + authToken;
    return authToken;
  };

  static sendRequest = (tipo, endpoint, accion, data, callback) => {
    //const URL_PATH = process.env.REACT_APP_API_URL;
    const URL_PATH = 'https://sub.softguild.com.ar/tmkrep/ws';
    let URL_FULL = "";

    if (!data) {
      data = {};
    }

    // data.uuid = this.getUUID();
    // data.authToken = this.getAuthToken();
    data.app = "TMK-RECEP-PLAYA";
    data.accion = accion;
    data.PHPSESSID = this.getAuthToken();
    URL_FULL = `${URL_PATH}/${endpoint ? endpoint + "/" : accion}`;
    axios.post(URL_FULL, data, { withCredentials: true }).then((response) => {
      if (response && response.data) {
        console.log('response', response)
        if (response.data.success && response.data.authToken) {
          this.setAuthToken(response.data.authToken);
        }
        if (!response.data.success && response.data.errors && (response.data.errors.length > 0) && (response.data.errors[0].code === 10201)) {
          // Vino ERR_INVALID_LOGIN, hacemos logout
          localStorage.setItem('repUser', null)
        }
        callback(response.data);
      }
    });

  };

  static getUIData = (accion, postData = {}, callback, errorCallback) => {
    this.current.sendRequest("ui", accion, postData, (response) => {
      if (response.success) {
        if (callback) {
          callback(response.data)
        };
      } else {
        if (errorCallback) {
          errorCallback(response);
        }
      }
    });
  };

  static getList(lista, data = {}, successCallback, errorCallback) {
    data.lista = lista;
    this.current.sendRequest('query','getList', data, (response) => {
      if (response.success && successCallback) {
        return successCallback(response);
      }
      if (!response.success && errorCallback) {
        return errorCallback(response);
      }
    })
  }

  static navigate(dispatch, pagina, toggleNavMenu) {
    if (!!dispatch && !this.current.dispatch) {
      this.current.dispatch = dispatch;
    }
    this.current.navigate(pagina, toggleNavMenu);
  }

}
