// import { Grid2 } from '@mui/material/Unstable_Grid2';
// import Grid from '@mui/material/Grid'; // Grid version 1
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Check as CheckIcon } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { Stack } from '@mui/system';

const ReservaCard = ({reserva, onSelected}) => {
  return (
    <Card className="reserva-card"
      sx={{backgroundColor: reserva?.estado?.color ? reserva.estado.color : 'whitesmoke'}}
      >
      <CardContent className='reserva-card__content'>
        {/* <Stack direction='row' spacing={2}> */}
        <Grid2 container spacing={4}>
          <Grid2 xs={4}>
            <Stack spacing={2} direction='row'>
              <Typography className='reserva__codigo'>{reserva.codigoReserva} </Typography>
              {reserva?.tomadaPor ? <Typography className='reserva__codigo' padding={1} sx={{color: 'white', backgroundColor: 'black'}}>{'Reserva tomada por' + ' ' + `${reserva.tomadaPor.nombreDeUsuario}`}</Typography> : null}
            </Stack>
          </Grid2>
          <Grid2 xs={8}>
            <Typography className='reserva__nombre'>{reserva.institucion}</Typography>
          </Grid2>
          <Grid2 xs={4}>
            <Typography className='reserva__estado'>{reserva.estado.nombre}</Typography>
          </Grid2>
          <Grid2 xs={8}>
            <Typography className='reserva__programa'>{reserva.programa}</Typography>
          </Grid2>
        </Grid2>
        {/* </Stack> */}
      </CardContent>
      <CardActions className='reserva-card__actions' sx={{ paddingRight: '2em' }}>
        <Button variant="contained" className='check-button' color='primary' onClick={onSelected}>
          <CheckIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

export default ReservaCard;