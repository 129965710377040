import { Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, SvgIcon, TextField } from '@mui/material';
import { ArrowForward as ArrowForwardIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import APIManager from '../../lib/apiManager';

const PasswordInput = ({ password, setPassword, showPassword, setShowPassword }) => {

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }

  return <FormControl className='' variant="filled">
    <InputLabel htmlFor="filled-adornment-password">Contraseña</InputLabel>
    <FilledInput
      id="filled-adornment-password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={handleChangePassword}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {setShowPassword(!showPassword)}}
            // onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>
}

const Login = ({ doLogin }) => {

  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const loginUser = () => {
    const data = {
      username: usuario,
      password
    };
    doLogin(data);
  }

  return (
    <div className='main-content login-page'>
      <div className='login-container'>
        <div className='form-inputs'>
          <TextField required className='usuario-input' id="filled-search" label="Usuario" variant="filled" value={usuario} onChange={(event) =>{setUsuario(event.target.value)}} />
          <PasswordInput className='password-input'  password={password} setPassword={setPassword} showPassword={showPassword} setShowPassword={setShowPassword} />
        </div>
        <Button
          variant="contained"
          color="primary"
          className='login-button primary'
          endIcon={<ArrowForwardIcon />}
          onClick={loginUser}
        >
          Iniciar sesión
        </Button>
      </div>
    </div>
  );
}

export default Login;