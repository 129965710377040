import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MainBar from '../../components/MainBar';
import { Container, createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const tmkTheme = createTheme({
  palette: {
    primary: {
      light: '#09c287',
      main: '#08996B',
      dark: '#045a3f',
      contrastText: '#fff',
    },
  },
});

const Main = ({ usuario, isPortrait, doLogout, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!usuario) {
      navigate('/login');
    } else {
      if (location.pathname.includes('login')) {
        navigate('/search');
      }
    }
  }, [usuario]);

  return (
    <ThemeProvider theme={tmkTheme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters={true} className={'main-container ' + (isPortrait ? 'portrait' : 'landscape')}>
        <MainBar isPortrait={isPortrait} usuario={usuario} doLogout={doLogout} />
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default Main;