import { ExitToApp } from '@mui/icons-material';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';

const MainBar = ({isPortrait, usuario, doLogout, ...props}) => {
  return (
    <AppBar position="static" className='status-bar'>
      <Toolbar variant="dense">
        <Typography variant="h6" color="inherit" component="div" className='status-bar__title' >
          {(!isPortrait ? 'Fundación Temaikèn - ' : '') + 'Recepción en Playa'}
        </Typography>
        {!!usuario ? <><span>{usuario.nombre}</span><IconButton color='inherit' className='status-bar__exit' onClick={doLogout}><ExitToApp /></IconButton></> : null}
      </Toolbar>
    </AppBar>
  );
}

export default MainBar;