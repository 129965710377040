import React, { useEffect, useLayoutEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './screens/Login';
import Main from './screens/Main';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Search from './screens/Search';
import APIManager from './lib/apiManager';
import Reserva from './screens/Reserva';
import Seccion1 from './screens/Reserva/secciones/Seccion1/seccion1';
import Seccion2 from './screens/Reserva/secciones/Seccion2/seccion2';
import Seccion3 from './screens/Reserva/secciones/Seccion3/seccion3';
import Seccion4 from './screens/Reserva/secciones/Seccion4/seccion4';
import { useInterval } from './lib/useInterval';

function App() {
  const [usuario, setUsuario] = useState(null);
  const [isPortrait, setIsPortrait] = useState(false);
  const [enSeccion, setEnSeccion] = useState(1)
  const [reservaSelected, setReservaSelected] = useState(null);

  /*
  {
    "codigoReserva": "49280",
    "institucion": "prueba cupo",
    "detalleReserva":
      [
        {"label": "Estado" ,"value": "A Confirmar"},
        {"label": "Programa" ,"value": "Somos Naturaleza"},
        {"label": "Hora" ,"value": "09:30hs"},
        {"label": "Vendedor" ,"value": "Martín Busto"},
        {"label": "Operador" ,"value": "Sturla"},
        {"label": "Observaciones" ,"value": "Estacionamienta liberado Directora Gladys Avalos, telefono 23454322"},
      ],
    "entradas":
      [
        {
          "descripcion": "Comos naturaleza corto menor",
          "codigoProducto": "638",
          "cantidad": "10",
          "cantidadLiberados": "2",
        },
        {
          "descripcion": "Somos Naturaleza Corto Mayor",
          "codigoProducto": "637",
          "cantidad": "5",
          "cantidadLiberados": "1",
        },
        {
          "descripcion": "Chofer",
          "codigoProducto": "639",
          "cantidad": "1",
          "cantidadLiberados": "2",
        },
        {
          "descripcion": "Coordinador",
          "codigoProducto": "640",
          "cantidad": "2",
          "cantidadLiberados": "1",
        },
      ],
    "estacionamiento":
      [
        {
          "descripcion": "Estacionamientoa",
          "codigoProducto": "650",
          "cantidad": "1",
          "cantidadLiberados": "1",
        },
      ],
    "gastronomia":
      [
        {
          "codigoActividad": "102",
          "actividad": "Refrigerio en Cebras",
          "horario": "11:00 - 12:00",
          "combosGastronomicos":
            [
              {
                "descripcion": "Combo Escolar 2 Hambur ",
                "codigoProducto": "294",
                "cantidad": "9",
                "cantidadLiberados": "0",
              },
              {
                "descripcion": "Combo Escolar 1 Pancho ",
                "codigoProducto": "293",
                "cantidad": "9",
                "cantidadLiberados": "0",
              }
            ],
        },
        {
          "codigoActividad": "107",
          "actividad": "Desayuno en Choique Aike",
          "horario": "12:00 - 12:20",
          "combosGastronomicos":
            [
              {
                "descripcion": "Cmbo Escolar 6 Milanesa pollo",
                "codigoProducto": "653",
                "cantidad": "15",
                "cantidadLiberados": "0",
              }
            ]
        }
    ],
    "usuario": {
        "0": {
            "usuarioPortalWeb": "desarrollo",
            "idUsuarioPortalWeb": "48",
            "usuarioCAV": "administrador",
            "rol":"1"
        }
    }
  })
 */

  const handleCantidades = (array, item, parametro, cantidad) => {

    array.map((element) => {

      if (element.codigoProducto == item.codigoProducto) {
        element[parametro] = parseInt(element[parametro]) + cantidad
      }

    })

    setReservaSelected({...reservaSelected})

  }

  const doLogout = () => {
    APIManager.sendRequest('post', 'usuario', 'logout', {}, (response) => {
      if (response.success) {
        setUsuario(null);
      }
    });
  }

  const doLogin = (userData) => {
    APIManager.sendRequest('post', 'usuario', 'login', userData, (response) => {
      if (response.success) {
        setUsuario(response.data.usuario);
      }
    });
  }

  const doCheckLogin = (userData) => {
    APIManager.sendRequest('post', 'usuario', 'me', {}, (response) => {
      if (response.success) {
        setUsuario(response.data.usuario);
      } else {
        setUsuario(null);
      }
    });
  }

  useLayoutEffect(() => {
    function updateIsPortrait() {
      setIsPortrait(window.innerWidth < window.innerHeight);
    }
    window.addEventListener("resize", updateIsPortrait);
    updateIsPortrait();
    return () => window.removeEventListener("resize", updateIsPortrait);
  }, []);

  useEffect(() => {
    if (!usuario) {
      doCheckLogin()
    }
  }, [usuario])

  useInterval(() => {
    doCheckLogin()
  }, 60000)

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main isPortrait={isPortrait} usuario={usuario} doLogout={doLogout} />} >
          <Route index element={<Search isPortrait={isPortrait} />} />
          <Route path='login' element={<Login isPortrait={isPortrait} doLogin={doLogin} />} />
          <Route path='search' element={<Search isPortrait={isPortrait} setReservaSelected={setReservaSelected} />} />
          <Route path='reserva' element={<Reserva isProtrait={isPortrait} reserveSelected={reservaSelected} setReservaSelected={setReservaSelected} enSeccion={enSeccion} setEnSeccion={setEnSeccion} />} >
            <Route path='seccion1' element={<Seccion1 isPortrait={isPortrait} reserveSelected={reservaSelected} />} />
            <Route path='seccion2' element={<Seccion2 isPortrait={isPortrait} reserveSelected={reservaSelected} handleCantidades={handleCantidades} />} />
            <Route path='seccion3' element={<Seccion3 isPortrait={isPortrait} reserveSelected={reservaSelected} handleCantidades={handleCantidades} />} />
            <Route path='seccion4' element={<Seccion4 isPortrait={isPortrait} reserveSelected={reservaSelected} handleCantidades={handleCantidades} />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
