import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';

const Seccion1 = ({isPortrait, reserveSelected, ...props}) => {

  return (
    <>
      {isPortrait ?
        <>Mobile</>
      :
        <>
          <TableContainer >
            <Table sx={{ width: 650, margin: 10 }} aria-label="simple table">
              <TableBody>
                {reserveSelected?.detalleReserva.map((item, index) => {
                  return(
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        {item.label}
                      </TableCell>
                      <TableCell>{item.value}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </>
  )
}

export default Seccion1;