import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, ButtonGroup } from '@mui/material';
import { useState } from 'react';

const Seccion2 = ({isPortrait, reserveSelected, handleCantidades, ...props}) => {

  // const {isPortrait, reserveSelected, handleCantidades} = props

  return (
    <>
      {isPortrait ?
        <>Mobile</>
      :
        <>

          <TableContainer component={Paper} sx={{ width: 750, margin: 10 }}>
            <Table sx={{ width: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 320 }}>Entrada</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell align="center">Liberados</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!reserveSelected && !!reserveSelected.entradas && reserveSelected.entradas.map((item, index) => {
                  const displayCounter = 0;
                  return(
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        {item.descripcion} ({item.cantidadOriginal}/{item.cantidadLiberadosOriginal})
                      </TableCell>
                      <TableCell align="center">

                        <ButtonGroup size="medium" variant="contained" aria-label="outlined primary button group">
                          <Button
                            onClick={() => handleCantidades(reserveSelected.entradas, item, 'cantidadRecepcionada', -1)}
                          >-</Button>
                          <Button sx={{width: '55px'}}>{item.cantidadRecepcionada}</Button>
                          <Button
                            onClick={() => handleCantidades(reserveSelected.entradas, item, 'cantidadRecepcionada', 1)}
                          >+</Button>
                        </ButtonGroup>

                      </TableCell>
                      <TableCell align="center">

                        {item.cantidadLiberados}

                      </TableCell>
                      <TableCell align="center">

                        {parseInt(item.cantidadLiberados) + parseInt(item.cantidadRecepcionada)}

                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ width: 750, margin: 10 }}>
            <Table sx={{ width: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                <TableCell sx={{ width: 320 }}>Entrada</TableCell>
                  <TableCell align="center">Cantidad</TableCell>
                  <TableCell align="center">Liberados</TableCell>
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!reserveSelected && !!reserveSelected.estacionamiento && reserveSelected.estacionamiento.map((item, index) => {
                  return(
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                      <TableCell component="th" scope="row">
                        {item.descripcion}
                      </TableCell>
                      <TableCell align="center">

                        <ButtonGroup size="medium" variant="contained" aria-label="outlined primary button group">
                          <Button
                            onClick={() => handleCantidades(reserveSelected.estacionamiento, item, 'cantidadRecepcionada', -1)}
                          >-</Button>
                          <Button sx={{width: '55px'}}>{item.cantidadRecepcionada}</Button>
                          <Button
                            onClick={() => handleCantidades(reserveSelected.estacionamiento, item, 'cantidadRecepcionada', 1)}
                          >+</Button>
                        </ButtonGroup>

                      </TableCell>
                      <TableCell align="center">

                        {item.cantidadLiberados}

                      </TableCell>
                      <TableCell align="center">

                        {parseInt(item.cantidadLiberados) + parseInt(item.cantidadRecepcionada)}

                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

        </>
      }
    </>
  )
}

export default Seccion2;